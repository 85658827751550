$primary: #005E5D;
$secondary: #D6E85F;
$secondary-text-color: $primary;

$font-family-base: 'CorporateS';
$header-logo-url: url('../img/logo-rvk.svg');

@mixin font-include() {
  @import '../../../assets/fonts/rvk.css';
}
@import "~bootstrap/scss/functions";
//see _variables.scss for all possible bootstrap variables
@import "~bootstrap/scss/mixins/container";
@import "~bootstrap/scss/mixins/transition";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/gradients";
@import "~bootstrap/scss/mixins/box-shadow";
@import "~bootstrap/scss/mixins/buttons";
@import "~bootstrap/scss/mixins/forms";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/vendor/rfs";

$enable-shadows: false;

$primary: #333 !default;
$secondary: #CCC !default;
$secondary-text-color: #FFFFFF !default;
$danger: #D69C9C !default;
$header-logo-url: url('../img/logo-rvk.svg') !default;
$grid-gutter-width: 4rem;

$body-color: $primary !default;
$banner-bg-color: $primary !default;
$banner-text-color: $secondary-text-color !default;
$btn-box-shadow: none !default;

$toggle-active-background-color: $primary !default;
$toggle-active-text-color: $secondary !default;

$sort-background-color: $primary !default;
$sort-text-color: #FFF !default;

$font-size-base: 1.6rem !default;
$font-size-sm: 1.4rem !default;

$header-text-transform: uppercase !default;
$title-font-family: adobe-caslon-pro,Times New Roman,Times,serif !default;

// map options
$map-kanton-enable: false !default;
$map-kanton-fill-color: #E5E3DF !default;
$map-country-opacity: 0.8 !default;
$map-kanton-disabled-color: $primary !default;
$map-kanton-disabled-opacity: 0.2 !default;
$map-center-fit-bounds: true !default;
$map-radius: 180 !default;

////// dropdown
$dropdown-bg: white !default;
$dropdown-link-active-bg: white !default;
$dropdown-link-active-color: #DEDEDE !default;
$dropdown-link-hover-bg: $primary !default;
$dropdown-link-hover-color: white !default;

////// modal
$modal-lg: 960px !default;
$modal-content-border-radius: 0 !default;
$modal-inner-padding: 3rem !default;

///// forms
$form-floating-height: 4.2rem !default;
//$form-floating-line-height: 1 !default;
$form-floating-padding-y: 1rem !default;
$form-floating-padding-x: 1rem !default;
$form-floating-input-padding-t: .75rem !default;
$form-floating-label-opacity: 0.5 !default;
$form-floating-label-transform: scale(.85) translateY(-1rem) translateX(.5rem) !default;

$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$primary}' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z'/></svg>");
$form-select-feedback-icon-size: 24px 20px;
$form-check-input-border: 1px solid $primary;

$input-btn-focus-width: 0;
$input-btn-padding-y: 0.5rem;

$border-radius: 1rem !default;
$border-radius-lg: 1.5rem !default;
$dropdown-min-width: 0;

////// pagination
$pagination-border-radius: 0;
$pagination-border-width: 0;
$pagination-active-bg: #F5F8E7;
$pagination-active-color: $primary;

///// modal
$modal-header-border-width: 0;

@import "~bootstrap/scss/_variables";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack
